import { MenuOutlined } from "@ant-design/icons";

import { useState } from "react";
import DrawerPanel from "./drawer/drawer";
import "./header.scss";

export default function Header() {
  const [openDrawer, setOpenDrawer] = useState(false);

  /**
   * Open or Close drawer
   */
  const handleDrawerState = () => {
    setOpenDrawer((prevState) => !prevState);
  };

  return (
    <div className="header">
      <img className="img" src="images/traveledmap-black.png" alt="logo"></img>
      <MenuOutlined style={{ fontSize: "35px" }} onClick={handleDrawerState} />
      <DrawerPanel open={openDrawer} handleDrawerState={handleDrawerState} />
    </div>
  );
}
