import React, { useEffect, useRef, useState } from "react";
import Globe from "react-globe.gl";
import { a } from "../../data/a";
import CountrieModal from "./countrieModal/countrieModal";

export default function SimpleGlobe() {
  const globeEl = useRef();
  const [selectedCountrie, setSelectedCountrie] = useState(null);
  const [countries, setCountries] = useState({ features: [] });
  const [altitude, setAltitude] = useState(0.1);
  const [color, setColor] = useState("black");
  const [transitionDuration, setTransitionDuration] = useState(1000);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // load data

    a.features.forEach((el) => {
      el.properties.color = "rgba(155,155,155,1)";
      el.properties.hasBeenVisited = false;
    });
    setCountries(a);

    setTimeout(() => {
      setTransitionDuration(4000);
      setAltitude(
        () => (feat) =>
          Math.max(0.1, Math.sqrt(+feat.properties.pop_est) * 7e-5)
      );
      setColor(() => (feat) => feat.properties.color);
    }, 3000);

    // default parameters of globe
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.3;
    globeEl.current.pointOfView({ altitude: 4 }, 5000);
  }, []);

  /**
   * trigger when click on a polygon
   * @param {Object} polygon
   */
  const handleOnPolygonClick = (polygon) => {
    setSelectedCountrie(polygon);
    setIsModalOpen(true);
    globeEl.current.controls().autoRotate = false;
  };

  /**
   * Open or Close Modal
   */
  const handleModalState = () => {
    setIsModalOpen((prevState) => !prevState);
    globeEl.current.controls().autoRotate = true;
  };

  return (
    <>
      <Globe
        ref={globeEl}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-dark.jpg"
        //   globeImageUrl="//unpkg.com/three-globe"
        backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
        polygonsData={countries.features}
        polygonAltitude={altitude}
        polygonCapColor={color}
        polygonSideColor={() => "rgba(0, 100, 0, 0.15)"}
        polygonLabel={({ properties: d }) => `
        <b>${d.name_fr} (${d.adm0_a3})</b> <br />
        Population: <i>${Math.round(+d.pop_est / 1e4) / 1e2}M</i>
        `}
        polygonsTransitionDuration={transitionDuration}
        onPolygonClick={handleOnPolygonClick}
        // onPolygonHover={handleOnPolygonHover}
      />

      <CountrieModal
        isModalOpen={isModalOpen}
        selectedCountrie={selectedCountrie}
        handleModalState={handleModalState}
      />
    </>
  );
}
