import React from "react";
import "./App.css";
import Globe from "./components/globe/globe";
import Header from "./components/header/header";

export default function App() {
  return (
    <div className="container">
      <Header />
      <Globe />
    </div>
  );
}
