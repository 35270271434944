import { Drawer } from "antd";
import React from "react";

export default function DrawerPanel(props) {
  const { open, handleDrawerState } = props;
  return (
    <Drawer
      title="Basic Drawer"
      placement="right"
      onClose={handleDrawerState}
      open={open}
    >
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Drawer>
  );
}
