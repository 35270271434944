import { Modal } from "antd";
import React from "react";
import "./countrieModal.scss";

export default function CountrieModal(props) {
  const { isModalOpen, handleModalState, selectedCountrie } = props;

  if (!selectedCountrie) return;
  return (
    <Modal
      centered
      title={selectedCountrie.properties.name_fr}
      open={isModalOpen}
      onOk={handleModalState}
      onCancel={handleModalState}
      destroyOnClose
      width={1000}
      className="modalBody"
    >
      {selectedCountrie.properties.hasBeenVisited ? "visited" : "not visited"}
    </Modal>
  );
}
